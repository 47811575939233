<template>
  <v-date-picker
    v-model="date"
    :mode="type"
    class="border-none"
    :is-required="required"
    update-on-input
    :first-day-of-week="2"
    :min-date="minDate"
    :max-date="maxDate"
    :masks="masks"
    :is24hr="is24Hr"
    :popover="{ positionFixed: true }"
    :locale="locale"
  >
    <template #default="{ inputValue, inputEvents, togglePopover }">
      <slot
        name="input"
        :inputValue="inputValue"
        :inputEvents="inputEvents"
        @click="togglePopover()"
      />
    </template>
  </v-date-picker>
</template>

<script>
import store from '@/store'
import { getDateFormat } from '@/utils/date'
import { calendarSettings, getDate, normalizeDateTimeFormats } from '../helpers'
import { dateTypes } from '@/constants/date'

export default {
  props: {
    locale: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'date',
      validator(value) {
        return [dateTypes.DATE, dateTypes.DATE_TIME].includes(value)
      }
    },
    modelValue: {
      type: [String, Date],
      default: null
    },
    noPastDates: {
      type: Boolean,
      default: false
    },
    noFutureDates: {
      type: Boolean,
      default: false
    },
    minThreshold: {
      type: [String, Date],
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    dateFormat: {
      type: String,
      default: undefined
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      maxDate: this.noFutureDates ? new Date() : null
    }
  },
  computed: {
    date: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    minDate() {
      if (this.minThreshold) return getDate(this.minThreshold)
      return this.noPastDates ? new Date() : null
    },
    computedDateFormat() {
      return this.dateFormat || getDateFormat()
    },
    is24Hr() {
      return !this.computedDateFormat.includes(' aa')
    },
    masks() {
      const dateFormat = normalizeDateTimeFormats(this.computedDateFormat)

      if (this.type === dateTypes.DATE) {
        return { ...calendarSettings.formats, input: dateFormat }
      }
      if (this.type === dateTypes.DATE_TIME && this.is24Hr) {
        return { ...calendarSettings.formats, inputDateTime24hr: dateFormat }
      }
      if (this.type === dateTypes.DATE_TIME && !this.is24Hr) {
        return { ...calendarSettings.formats, inputDateTime: dateFormat }
      }
      return calendarSettings.formats
    }
  }
}
</script>
