<template>
  <date-picker
    v-model="localeValue"
    :type="type"
    :locale="$i18n.locale"
    :no-future-dates="noFutureDates"
    :no-past-dates="noPastDates"
    :min-threshold="minThreshold"
    :date-format="computedDateFormat"
    :required="false"
    style="line-height: initial"
  >
    <template #input="{ inputValue, inputEvents }">
      <div class="el-input" :class="{ 'is-disabled': disabled }">
        <!-- v-calendar doesn't work well out-of-the-box with el-input -->
        <div class="el-input__wrapper">
          <input
            :id="id"
            ref="input"
            class="el-input__inner"
            :disabled="disabled"
            :placeholder="placeholder"
            :value="inputValue"
            v-on="omit(inputEvents)"
          />
        </div>
      </div>
    </template>
  </date-picker>
</template>

<script>
import { getDateFormat, getStartOfDay } from '@/utils/date'
import typeOf from 'just-typeof'
import omit from 'just-omit'
import DatePicker from '@/components/date-picker/single/single-date-picker'
import { getDate } from '@/components/date-picker/helpers'
import { dateTypes } from '@/constants/date'

export default {
  components: {
    DatePicker
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: navigator.language
    },
    type: {
      type: String,
      default: 'date',
      validator(value) {
        return [dateTypes.DATE, dateTypes.DATE_TIME].includes(value)
      }
    },
    noPastDates: {
      type: Boolean,
      default: false
    },
    noFutureDates: {
      type: Boolean,
      default: false
    },
    minThreshold: {
      type: [String, Date],
      default: null
    },
    dateFormat: {
      type: String,
      default: undefined
    },
    id: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    returnDateType: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localeValue: {
      get() {
        if (this.type === dateTypes.DATE_TIME) {
          return this.modelValue
        }
        /*
         * This is a hack to remove time from date,
         * Since we don't want date-picker component to round up the date.
         */
        return this.modelValue && getStartOfDay(this.modelValue)
      },
      set(modelValue) {
        this.$emit('update:modelValue', this.handleInput(modelValue))
      }
    },
    computedDateFormat() {
      return this.dateFormat || getDateFormat()
    }
  },
  methods: {
    handleInput(value) {
      if (this.returnDateType) return getDate(value)
      if (typeOf(value) === 'date') return value.toISOString()
      return value
    },
    omit(inputEvents) {
      /*
        v-calendar inputEvents: change, click, focusin, focusout, input, keyup, mousemove.
        Mouse over events are not needed so we remove them.
      */
      return omit(inputEvents, ['mousemove', 'mouseleave'])
    }
  }
}
</script>
